var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-terms" }, [
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h1", { staticClass: "c-title2 c-title2--mb8" }, [
          _vm._v(" 「COFFEE CREATION UCCアプリ」"),
          _c("br"),
          _vm._v(" ご利用規約 "),
        ]),
        _c("p", [
          _vm._v(
            " UCC上島珈琲株式会社（以下「当社」といいます）は、当社が提供するアプリケーションソフトウェア「COFFEE CREATION UCCアプリ」の利用に関して、以下の通り、規約（以下「本規約」といいます）を定めます。 "
          ),
        ]),
        _c("p", [
          _vm._v(
            " 利用者が本アプリの利用を開始した場合には、本規約に同意したものとみなします。"
          ),
          _c("br"),
          _vm._v(
            " なお、利用者が未成年の場合、利用者は、保護者が本規約を確認の上、これを条件として利用者が本アプリを利用することについて同意したことを確認するものとします。利用者又は保護者が本規約に同意しない場合には、本アプリを利用することはできません。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（定義）"),
        ]),
        _c("p", [
          _vm._v(
            "本規約で用いられる次の用語は、それぞれ次の意味で使用します。"
          ),
        ]),
        _c("h3", { staticClass: "p-terms__section__title2" }, [
          _vm._v("「本アプリ」:"),
        ]),
        _c("p", [
          _vm._v(
            " 当社が提供するアプリケーションソフトウェア「COFFEE CREATION UCCアプリ」をいいます。 "
          ),
        ]),
        _c("h3", { staticClass: "p-terms__section__title2" }, [
          _vm._v("「対象機器」:"),
        ]),
        _c("p", [
          _vm._v(
            " 当社が指定する、DRIP POD YOUBI機種を始めとした、本アプリと接続可能な飲料抽出機器を指します。対象機器は、本アプリを指定の方式で接続することで、アプリから指示した飲料抽出機能等を利用できます。 "
          ),
        ]),
        _c("h3", { staticClass: "p-terms__section__title2" }, [
          _vm._v("「本サービス」:"),
        ]),
        _c("p", [
          _vm._v(
            "第3条第1項に記載の、本アプリで提供するサービスの総称をいいます。"
          ),
        ]),
        _c("h3", { staticClass: "p-terms__section__title2" }, [
          _vm._v("「利用者」:"),
        ]),
        _c("p", [_vm._v("本アプリを利用する全てのお客様をいいます。")]),
        _c("h3", { staticClass: "p-terms__section__title2" }, [
          _vm._v("「本キャンペーン」:"),
        ]),
        _c("p", [
          _vm._v(
            " 本アプリ内で当社が実施する利用者限定のプレゼント企画、イベント企画等をいいます。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（規約の適用）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 本規約は、本アプリの利用、本キャンペーンへの応募等により生じる利用者と当社との間の一切の関係に適用されます。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本アプリ内のWEBサイト及び本キャンペーンの応募専用WEBサイト内に掲載されるルール、応募要項、注意事項等は、本規約の一部を構成するものとします。なお、本規約の内容と当該ルール等の内容とが異なる場合、当該ルール等が優先して適用されるものとします。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（本サービスの内容）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _c("p", [_vm._v("本サービスの内容は、次の各号に記載の通りです。")]),
            _c("ol", { staticClass: "c-orderedList c-orderedList--circle" }, [
              _c("li", [
                _vm._v(
                  " 対象機器と本アプリを当社が指定する方式で接続して行う飲料抽出機能 "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " 本キャンペーンの応募用WEBサイトへのアクセス、及び本キャンペーンへの応募 "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " コーヒー関連等のコンテンツWEBサイトの紹介、当該サイトへのアクセス等 "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " 登録情報及び利用者の利用状況に連動した利用者ごとの最適な広告等の提供 "
                ),
              ]),
            ]),
          ]),
          _c("li", [
            _vm._v(
              " 当社は、随時、利用者に対する予告なく本サービスの内容を変更することができるものとします。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本サービスは日本国内限定のサービスであり、利用者は日本国内に滞在する者に限ります。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社の社員及び関係者は、本サービスの一部を利用することはできません。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（対象機器と接続した抽出機能）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 利用者は、当社が指定する接続方法により、対象機器と本アプリを接続して、本アプリから指示した飲料抽出機能を利用することができます。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 利用者は、当社が企画設計した「抽出レシピ」を利用して、飲料の抽出を行うことができます。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 「抽出レシピ」を始めとしたコンテンツは、予告なく変更または削除する場合があります。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（連携先サービスの利用）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 本アプリでは、連携先サービスへのリンクを表示する場合があります。また、本キャンペーンへの応募等、一部のサービスの利用には、本アプリと連携先サービスとの連携設定が必要になります。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 連携先サービスは、第三者により提供又は運営されるものであり、当社が、その正確性、完全性、適時性、有効性、適法性、適正性、品質等につき責任を負うものではありません。連携先サービスの利用に当たっては、各サービスの利用規約等に同意の上、利用者自身の責任においてご利用下さい。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本アプリと連携先サービスとの連携を解除する場合、連携先サービスにおける所定の手続が必要になります。なお、解除が完了した場合、一部のサービスが利用できなくなる場合があります。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（本アプリのバージョンアップ）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 利用者への予告なく本アプリのバージョンアップが行われる場合があります。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本アプリのバージョンアップが行われる場合において、利用者がバージョンアップ後の本アプリをインストールするまでの間、本サービスの全部又は一部を利用できない場合があります。利用者は、自己の責任と判断において、本アプリのバージョンアップを行うものとします。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（個人情報の利用目的、取扱い）"),
        ]),
        _c("p", [
          _vm._v(
            " 当社は、本サービスの利用に伴い利用者から取得する個人情報を以下の目的に利用します。なお、当社の個人情報保護方針については当社グループ会社ウェブサイト（https://www.ucc.co.jp/privacy/）をご覧ください。 "
          ),
        ]),
        _c("ol", { staticClass: "c-orderedList c-orderedList--circle" }, [
          _c("li", [_vm._v("本アプリの提供のため")]),
          _c("li", [
            _vm._v(
              " 当社および当社グループ会社の商品の品質やサービスの質の向上を目的としたアンケート調査のご協力依頼およびそれに付随する諸対応のため "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社および当社グループ会社の商品やサービス等に関するお知らせをメールないしウェブサイト閲覧画面上に表示する方法でお届けするため "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 各種キャンペーン等の当選、モニターの採用等の通知、商品・謝礼の提供、イベントの企画・運営に関する諸対応のため "
            ),
          ]),
          _c("li", [_vm._v("お問合せに対するお返事をお届けするため")]),
          _c("li", [
            _vm._v(
              "マーケティングや商品開発等の統計的データとして利用するため"
            ),
          ]),
          _c("li", [_vm._v("なんらかの必要に応じて利用者と連絡を取るため")]),
          _c("li", [
            _vm._v(
              " 利用者ごとに本アプリ内の表示内容をカスタマイズする等、サービスの質を向上させ、利用者の便宜を図るため "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（著作権等）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 本アプリ上で表示される各種情報等に関する知的財産権（著作権、特許権、実用新案権、意匠権、商標権、ノウハウ等を含みますがこれらに限定されません。以下同じ）は、当社またはそれぞれの権利の権利者に帰属します。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本サービスにおいて利用者が登録した情報等に関する知的財産権は、利用者に帰属します。ただし、当社および当社の指定する第三者は、利用者が登録した情報等を無期限かつ無償で使用等できるものとし、また、利用者は、当社および当社の指定する第三者に対して著作者人格権およびその他一切の権利を行使または主張しないものとします。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（禁止事項）"),
        ]),
        _c("p", [
          _vm._v(
            " 利用者は、本サービスを利用するに際して、以下に掲げる行為をしてはならないものとします。 "
          ),
        ]),
        _c("ol", { staticClass: "c-orderedList c-orderedList--circle" }, [
          _c("li", [
            _vm._v(
              " 本アプリで表示される画像、データ、情報等の全てについて、その有償無償や形態の如何を問わず、事前に当社から書面による承諾を得ることなく、複製、転載、再配布等する行為 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社または第三者の著作権、特許権、実用新案権、意匠権、商標権、ノウハウ等その他の一切の知的財産権を侵害する行為 "
            ),
          ]),
          _c("li", [_vm._v("法令または公序良俗に反する行為")]),
          _c("li", [
            _vm._v("その他当社が合理的な理由に基づき不適切と判断する行為"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（免責）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 当社は、本アプリに不具合、エラー、障害等の瑕疵がないこと、および本サービスが中断なく提供されることをなんら保証しません。また、特定の目的に対する適応性、知的財産権その他の権利の侵害等に対してもなんら保証しません。当社は、本アプリにいかなる不備があってもそれを回復、訂正等する義務を負いません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社は、利用者が本アプリを利用すること、または、利用できなかったことによって損害、トラブル等が生じた場合であっても、いかなる責任も負いません。また、本アプリをインストールしたことで端末機の故障等の損害が生じた場合であっても、当社は責任を負わないものとします。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社は、利用者が本アプリを用いて対象機器を操作した際に、その誤操作により対象機器の故障等が生じた場合、いかなる責任を負いません。また、当社の故意または重過失により生じた場合を除き、本サービス利用により利用者に損害、トラブル等が生じた場合も責任を負わないものとします。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社は、利用者が自身のアプリを削除したことにより、本サービスの利用に関する一切の情報が消失した場合であっても、いかなる責任も負いません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社は、本アプリが全ての機器・デバイス上で、又はあらゆる状況で動作することについて保証しません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本サービスは、当社が企画・運営するものであり、Apple Inc.及びその関連会社、また、Alphabet Inc.(Google LLC)及びその関連会社は一切関係ありません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社は本アプリ上で表示される情報等について、明示または黙示を問わず、その正確性、完全性、最新性、および品質等についてなんら保証しません。また、当社は、本アプリ上で表示される情報等およびその変更、更新等に関連して、利用者に生じた一切の損害、トラブルに関していかなる責任も負いません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本アプリにおいて利用者が本規約に定める事項の一つにでも違反した場合、当社は、当該情報の全部または一部の削除、修正または編集等を行い、第11条の定めにしたがって本アプリの利用を中止させる場合がありますが、それによって利用者に生じた一切の損害に関していかなる責任も負いません。また、当社は、利用者が登録した情報等が本規約に定める事項に違反する場合であっても、それを削除等する義務を負いません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 当社は、本アプリの仕様に関するご質問には一切お答えいたしません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 本規約の一部の規定が法令に基づいて無効と判断された場合であっても、その他の規定は有効とします。また、本規約の一部が特定の利用者との間で無効とされ、または取り消された場合でも、本規約はその他の利用者との関係では有効とします。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（本アプリの中止等）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _vm._v(
              " 当社が必要と認める場合、当社はなんらの通知を行うことなく、本アプリの内容を変更したり、本アプリの全部または一部の提供を中止または終了することがあります。なお、当該変更、中止または終了により利用者に損害が生じた場合であっても、当社はいかなる責任も負いません。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 利用者が本規約に定める事項の一つにでも違反し、またはその疑いがある場合、当社は、なんらの通知を行うことなく本アプリの利用を停止させ、または調査のため一時的に本アプリの全部もしくは一部の利用を停止することができます。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（本規約等の変更）"),
        ]),
        _c("ol", { staticClass: "c-orderedList" }, [
          _c("li", [
            _c("p", [
              _vm._v(
                " 当社は以下の場合に、当社の裁量により、本規約を変更することができます。 "
              ),
            ]),
            _c("ol", { staticClass: "c-orderedList c-orderedList--circle" }, [
              _c("li", [
                _vm._v("本規約の変更が、利用者の一般の利益に適合するとき。"),
              ]),
              _c("li", [
                _vm._v(
                  " 本規約の変更が、本規約に基づく利用契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。 "
                ),
              ]),
            ]),
          ]),
          _c("li", [
            _vm._v(
              " 当社は前項による本規約の変更にあたり、変更後の本規約の効力発生日の１か月前までに、本規約を変更する旨および変更後の本規約の内容とその効力発生日を当社ウェブサイトに掲示し、または利用者にEメールもしくは印刷物等の送付により通知します。 "
            ),
          ]),
          _c("li", [
            _vm._v(
              " 変更後の本規約の効力発生日以降に利用者が本アプリを利用したときは、利用者は、本規約の変更に同意したものとみなします。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（損害賠償）"),
        ]),
        _c("p", [
          _vm._v(
            " 利用者が本規約に違反した結果、当社が損害を被った場合、利用者は、当社に対して、その損害（合理的な弁護士費用を含みます）のすべてを賠償する責任を負うこととします。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（法律等の遵守）"),
        ]),
        _c("p", [
          _vm._v(
            " 利用者は、本アプリの利用にあたって、本規約に加え、関連する法律、政令、省令、条例、規則および命令等を遵守するものとします。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("h2", { staticClass: "p-terms__section__title" }, [
          _vm._v("（準拠法および裁判管轄）"),
        ]),
        _c("p", [
          _vm._v(
            " 本規約に関する準拠法は日本法とします。また、本規約または本サービスに関連して当社と利用者間で紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "c-section c-section--mb24 p-terms_section" }, [
        _c("p", { staticClass: "p-terms__section__bottom" }, [_vm._v("以上")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }